import React from 'react';
import { useParams } from 'react-router-dom';
import Button from './Button';
import { Check } from 'lucide-react';

const UseCasePage = () => {
  const { useCase } = useParams();
  const useCaseData = {
    'healthcare': {
      title: 'AI Receptionist for Healthcare',
      description: 'Enhance patient care and streamline operations in healthcare facilities with our AI receptionist',
      benefits: [
        'Efficient appointment scheduling and management',
        'Handle patient inquiries and triage 24/7',
        'Reduce administrative workload for healthcare staff',
        'Improve patient satisfaction and care coordination',
      ],
      features: [
        'Intelligent appointment scheduling and reminders',
        'Patient information collection and pre-registration',
        'Symptom assessment and triage',
        'Medication refill requests handling',
        'Insurance verification and billing inquiries',
        'Multi-language support for diverse patient populations',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'car-dealerships': {
      title: 'AI Receptionist for Car Dealerships',
      description: 'Revolutionize your car dealership operations with our AI receptionist',
      benefits: [
        'Efficient appointment scheduling for test drives and service',
        'Handle customer inquiries 24/7',
        'Qualify leads and improve sales conversion',
        'Streamline service department operations',
      ],
      features: [
        'Automated appointment booking for sales and service',
        'Vehicle information and inventory inquiries',
        'Lead qualification and routing',
        'Service reminders and follow-ups',
        'Financing and trade-in information handling',
        'Multi-language support for diverse customer base',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'car-dealerships': {
      title: 'AI Receptionist for Car Dealerships',
      description: 'Revolutionize your car dealership operations with our AI receptionist',
      benefits: [
        'Efficient appointment scheduling for test drives and service',
        'Handle customer inquiries 24/7',
        'Qualify leads and improve sales conversion',
        'Streamline service department operations',
      ],
      features: [
        'Automated appointment booking for sales and service',
        'Vehicle information and inventory inquiries',
        'Lead qualification and routing',
        'Service reminders and follow-ups',
        'Financing and trade-in information handling',
        'Multi-language support for diverse customer base',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'dentists': {
      title: 'AI Receptionist for Dentists',
      description: 'Streamline your dental practice with our AI receptionist',
      benefits: [
        'Schedule appointments efficiently',
        'Handle patient inquiries 24/7',
        'Reduce no-shows with automated reminders',
        'Manage insurance verification',
      ],
      features: [
        'Appointment scheduling and management',
        'Patient information collection',
        'Insurance verification',
        'Emergency triage',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'small-businesses': {
      title: 'AI Receptionist for Small Businesses',
      description: 'Boost your small business efficiency with our AI receptionist',
      benefits: [
        'Never miss a customer call',
        'Reduce operational costs',
        'Improve customer service',
        'Focus on core business activities',
      ],
      features: [
        'Call routing and forwarding',
        'Customer inquiry handling',
        'Lead qualification',
        'After-hours support',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'clinics': {
      title: 'AI Receptionist for Clinics',
      description: 'Enhance patient care and streamline operations with our AI receptionist',
      benefits: [
        'Efficient appointment scheduling',
        '24/7 patient support',
        'Reduce administrative workload',
        'Improve patient satisfaction',
      ],
      features: [
        'Automated appointment reminders',
        'Patient triage and prioritization',
        'Medical record integration',
        'Prescription refill requests handling',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'gyms': {
      title: 'AI Receptionist for Gyms',
      description: 'Optimize your gym operations with our AI receptionist',
      benefits: [
        'Streamline membership management',
        'Handle class bookings efficiently',
        'Provide 24/7 member support',
        'Increase member retention',
      ],
      features: [
        'Class scheduling and management',
        'Membership inquiries and sign-ups',
        'Automated billing reminders',
        'Personalized fitness plan inquiries',
      ],
      videoUrl: 'https://www.youtube.com/embed/-DuETZtc1j0',
    },
    'yoga-studios': {
      title: 'AI Receptionist for Yoga Studios',
      description: 'Elevate your yoga studio experience with our AI receptionist',
      benefits: [
        'Effortless class bookings',
        'Manage instructor schedules',
        'Provide instant answers to member queries',
        'Increase studio efficiency',
      ],
      features: [
        'Class reservation and waitlist management',
        'New student orientation scheduling',
        'Membership package information',
        'Special events and workshop registration',
      ],
      videoUrl: 'https://www.youtube.com/embed/U-AyuZIz8kM',
    },
    'beauty-salons': {
      title: 'AI Receptionist for Beauty Salons',
      description: 'Enhance your beauty salon operations with our AI receptionist',
      benefits: [
        'Efficient appointment booking',
        'Handle customer inquiries 24/7',
        'Reduce no-shows with automated reminders',
        'Manage stylist schedules',
      ],
      features: [
        'Appointment scheduling and management',
        'Customer information collection',
        'Service inquiries handling',
        'Product recommendations',
      ],
      videoUrl: 'https://www.youtube.com/embed/bj6Itd1YzCQ',
    },
  };

  const data = useCaseData[useCase] || useCaseData['small-businesses']; // Default to small businesses if use case not found

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white">
      <header className="flex justify-between items-center p-4 max-w-7xl mx-auto">
        <div className="text-3xl font-bold text-gray-800 flex items-center">
          <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8 mr-2" />
          Aivira
        </div>
        <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-gray-900 hover:bg-gray-800 text-white rounded-md">Book a Demo</a>
      </header>
      
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-20">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            AI Receptionist for{' '}
            <span className="block md:inline">{useCase.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}s</span>
          </h1>
          <p className="text-xl mb-8">{data.description}</p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-12">
            <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-gray-900 hover:bg-gray-800 text-white rounded-md w-full md:w-auto">Book a Demo</a>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Benefits for {useCase.charAt(0).toUpperCase() + useCase.slice(1)}</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {data.benefits.map((benefit, index) => (
              <div key={index} className="flex items-start">
                <Check className="mr-2 text-green-500 flex-shrink-0" />
                <p>{benefit}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Key Features</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {data.features.map((feature, index) => (
              <div key={index}>
                <h3 className="text-2xl font-semibold mb-2">{feature}</h3>
                <p>Enhance your {useCase} practice with our advanced {feature.toLowerCase()} capabilities.</p>
              </div>
            ))}
          </div>
        </section>

        <section className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-8">Ready to transform your {useCase.split('-').join(' ')}?</h2>
          <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-gray-900 hover:bg-gray-800 text-white rounded-md">Get Started Now</a>
        </section>
      </main>
    </div>
  );
};

export default UseCasePage;
