// components/IntegrationsContent.js
import React, { useState } from 'react';
import { Calendar, PhoneOff, PhoneForwarded, Hash, Code } from 'lucide-react';

const IntegrationsContent = () => {
  const [connectedServices, setConnectedServices] = useState([
    { id: 1, name: 'End Call', icon: 'phone-off', configured: false, type: 'end_call' },
    { id: 2, name: 'Transfer Call', icon: 'phone-forward', configured: false, type: 'transfer_call' },
    { id: 3, name: 'Check Calendar Availability (Cal.com)', icon: 'calendar', configured: false, type: 'check_availability_cal' },
    { id: 4, name: 'Book on the Calendar (Cal.com)', icon: 'calendar', configured: false, type: 'book_appointment' },
    { id: 5, name: 'Press Digit (IVR Navigation)', icon: 'hash', configured: false, type: 'press_digit' },
    { id: 6, name: 'Custom Function', icon: 'function', configured: false, type: 'custom_function' },
  ]);

  const [showConfigModal, setShowConfigModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [error, setError] = useState(null);

  const toggleServiceConfiguration = (id) => {
    setConnectedServices(connectedServices.map(s => 
      s.id === id ? {...s, configured: !s.configured} : s
    ));
  };

  const getIconComponent = (iconName) => {
    switch (iconName) {
      case 'phone-off': return PhoneOff;
      case 'phone-forward': return PhoneForwarded;
      case 'hash': return Hash;
      case 'function': return Code;
      default: return Calendar;
    }
  };

  const openConfigModal = (service) => {
    setSelectedService(service);
    setShowConfigModal(true);
  };

  const closeConfigModal = () => {
    setShowConfigModal(false);
    setSelectedService(null);
  };

  const renderConfigFields = () => {
    if (!selectedService) return null;

    switch (selectedService.type) {
      case 'end_call':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="end_call" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description (Optional)</label>
              <textarea 
                className="w-full p-2 border rounded" 
                placeholder="Enter function description"
                rows="3"
              ></textarea>
            </div>
          </div>
        );
      case 'transfer_call':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="transfer_call" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description (Optional)</label>
              <textarea 
                className="w-full p-2 border rounded" 
                rows="3"
                defaultValue="When user is angry or requests a human agent, transfer the call to a human."
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Transfer to</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="+14154154155" />
            </div>
          </div>
        );
      case 'check_availability_cal':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="check_calendar_availability" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description (Optional)</label>
              <textarea 
                className="w-full p-2 border rounded" 
                rows="3"
                defaultValue="When users ask for availability, check the calendar and provide available slots."
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">API Key (Cal.com)</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter Cal.com API key" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Event Type ID (Cal.com)</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter Event Type ID" />
            </div>
            <div>
              <label className="block mb-2">Timezone (Optional)</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="America/Los_Angeles" />
            </div>
          </div>
        );
      case 'book_appointment':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="book_appointment" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description (Optional)</label>
              <textarea 
                className="w-full p-2 border rounded" 
                rows="3"
                defaultValue="When users ask to book an appointment, book it on the calendar."
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">API Key (Cal.com)</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter Cal.com API key" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Event Type ID (Cal.com)</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter Event Type ID" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Timezone (Optional)</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="America/Los_Angeles" />
            </div>
          </div>
        );
      case 'press_digit':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" defaultValue="press_digit" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description (Optional)</label>
              <textarea 
                className="w-full p-2 border rounded" 
                rows="3"
                defaultValue="Navigate to the human agent of sales department"
              ></textarea>
            </div>
            <p className="mt-2 text-sm text-gray-600">Configure the IVR navigation to transfer to a specific department or agent.</p>
          </div>
        );
      case 'custom_function':
        return (
          <div>
            <div className="mb-4">
              <label className="block mb-2">Name</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter the name of the custom function" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description</label>
              <textarea className="w-full p-2 border rounded" placeholder="Enter the description of the custom function" rows="3"></textarea>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Your URL</label>
              <input type="text" className="w-full p-2 border rounded" placeholder="Enter the URL of the custom function" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Parameters (Optional)</label>
              <textarea className="w-full p-2 border rounded" placeholder="Enter JSON Schema here..." rows="5"></textarea>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" />
                <span>Speak After Execution</span>
              </label>
            </div>
            <p className="mt-2 text-sm text-gray-600">Configure your custom function to extend the AI's capabilities.</p>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSaveConfig = async () => {
    if (selectedService) {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }

        // Check if payment methods exist
        const paymentMethodsResponse = await fetch('https://api.aivira.co/payment_methods', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (!paymentMethodsResponse.ok) {
          throw new Error('Failed to fetch payment methods');
        }

        const paymentMethodsData = await paymentMethodsResponse.json();
        if (!paymentMethodsData.payment_methods || paymentMethodsData.payment_methods.length === 0) {
          throw new Error('No payment methods found. Please add a payment method to proceed.');
        }

        // If payment methods exist, proceed with saving the configuration
        toggleServiceConfiguration(selectedService.id);
        closeConfigModal();
      } catch (error) {
        console.error("Failed to save configuration:", error);
        setError(error.message);
        closeConfigModal(); // Close the modal on error
      }
    }
  };

  return (
    <div className="space-y-8">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      {/* Connected Services Section */}
      <div>
        <h3 className="text-xl font-semibold mb-4">Connected Services</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {connectedServices.map(service => {
            const IconComponent = getIconComponent(service.icon);
            return (
              <div key={service.id} className="bg-white p-4 rounded-lg shadow flex items-center justify-between">
                <div className="flex items-center">
                  <IconComponent className="h-6 w-6 mr-2 text-gray-600" />
                  <span>{service.name}</span>
                </div>
                <div>
                  <button
                    onClick={() => openConfigModal(service)}
                    className={`px-3 py-1 rounded ${service.configured ? 'bg-green-500 text-white' : 'bg-blue-500 text-white'}`}
                  >
                    {service.configured ? 'Configured' : 'Configure'}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Configuration Modal */}
      {showConfigModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">{selectedService?.name}</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleSaveConfig(); }}>
              {renderConfigFields()}
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  onClick={closeConfigModal}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-black text-white rounded"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationsContent;
