import React, { useState, useEffect } from 'react';
import { Users, PhoneCall, Puzzle, History, CreditCard, Key, Box, HelpCircle, ChevronDown, ChevronRight, Menu, Wand2 } from 'lucide-react';
import LeftMenuItem from './LeftMenuItem';

const Sidebar = ({ activePage, setActivePage, activeAgent, setActiveAgent, handleLogout, isOpen, setIsOpen, hasPaymentMethod }) => {
  const [expandedMenus, setExpandedMenus] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      if (!newIsMobile) {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially
    return () => window.removeEventListener('resize', handleResize);
  }, [setIsOpen]);

  const toggleSubMenu = (menuName) => {
    setExpandedMenus(prev => ({...prev, [menuName]: !prev[menuName]}));
  };

  const renderMenuItem = (icon, text, page, subItems = []) => {
    const isActive = activePage === page;
    const isExpanded = expandedMenus[page];
    const hasSubItems = subItems.length > 0;

    return (
      <div key={page}>
        <LeftMenuItem
          icon={icon}
          text={text}
          active={isActive}
          onClick={() => {
            setActivePage(page);
            if (hasSubItems) toggleSubMenu(page);
            if (isMobile) setIsOpen(false);
          }}
          rightIcon={hasSubItems ? (isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />) : null}
        />
        {hasSubItems && isExpanded && (
          <div className="ml-6">
            {subItems.map((subItem, index) => (
              <LeftMenuItem
                key={index}
                icon={subItem.icon}
                text={subItem.text}
                active={activePage === subItem.page}
                onClick={() => {
                  setActivePage(subItem.page);
                  if (isMobile) setIsOpen(false);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`${isMobile ? 'fixed inset-y-0 left-0 z-40 transform transition-transform duration-300 ease-in-out' : 'relative'} ${
        isMobile && !isOpen ? '-translate-x-full' : 'translate-x-0'
      } w-64 bg-white shadow-md flex flex-col`}>
        <div className="p-4">
          <div className="flex items-center mb-4">
            {isMobile && (
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="mr-2 p-2 bg-white rounded-md"
              >
                <Menu size={24} />
              </button>
            )}
            <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8 mr-2" />
            <h2 className="text-xl font-bold">Aivira</h2>
          </div>
        </div>
        <div className="flex-grow">
          {renderMenuItem(<Users size={20} />, "AI Receptionist", "AI Receptionist")}
          {renderMenuItem(<PhoneCall size={20} />, "Phones", "Phones")}
          {renderMenuItem(<Puzzle size={20} />, "Integrations", "Integrations")}
          {renderMenuItem(<History size={20} />, "History", "History")}
          {renderMenuItem(<CreditCard size={20} />, "Billing", "Billing")}
        </div>
        <div className="p-4">
          {!hasPaymentMethod && (
            <div className="bg-gray-100 rounded-lg p-4 mb-4">
              <h3 className="text-sm font-semibold mb-2">Free Trial</h3>
              <p className="text-xs mb-2">Remaining: 60:00 minutes</p>
              <div className="w-full bg-green-200 rounded-full h-2.5">
                <div className="bg-blue-600 h-2.5 rounded-full w-full"></div>
              </div>
              <button
                onClick={() => setActivePage("Billing")}
                className="mt-3 w-full bg-blue-600 text-white text-sm py-2 px-4 rounded flex items-center justify-center"
              >
                <span className="mr-1">↗</span> Add Payment
              </button>
            </div>
          )}
          <button onClick={handleLogout} className="w-full bg-red-500 text-white py-2 px-4 rounded mt-2">
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
