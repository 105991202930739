import React from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';

const RealEstatePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white">
      <header className="flex justify-between items-center p-4 max-w-7xl mx-auto">
        <div className="text-3xl font-bold text-gray-800 flex items-center">
          <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8 mr-2" />
          Aivira
        </div>
        <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md">Book a Demo</a>
      </header>
      
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-20">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            AI Receptionist for Real Estate
          </h1>
          <p className="text-xl mb-8">Streamline property inquiries, schedule viewings, and provide 24/7 client support efficiently.</p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-12">
            <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md w-full md:w-auto">Book a Demo</a>
            <Link to="/" className="inline-block px-6 py-3 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md w-full md:w-auto">Learn More</Link>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Key Features for Real Estate</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">24/7 Property Inquiries</h3>
                <p>Handle property inquiries and provide information round-the-clock, even outside business hours.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Automated Viewing Scheduling</h3>
                <p>Efficiently schedule and manage property viewings, reducing manual coordination efforts.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">CRM Integration</h3>
                <p>Seamlessly integrate with your existing real estate CRM systems for efficient lead management.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Multilingual Support</h3>
                <p>Communicate with clients in multiple languages, expanding your market reach.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Benefits for Real Estate Professionals</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Increased Efficiency</h3>
              <p>Handle more inquiries and schedule viewings without increasing staff, maximizing productivity.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Improved Client Experience</h3>
              <p>Provide instant responses and 24/7 availability, enhancing client satisfaction and engagement.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Cost Reduction</h3>
              <p>Minimize operational costs while maintaining high-quality client interactions and support.</p>
            </div>
          </div>
        </section>

        <section className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-8">Ready to revolutionize your real estate business?</h2>
          <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-md">Get Started Now</a>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
          <div className="mt-4">
            <Link to="/privacy" className="text-gray-300 hover:text-white mr-4">Privacy Policy</Link>
            <Link to="/terms" className="text-gray-300 hover:text-white">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default RealEstatePage;