import React, { useState, useEffect } from 'react';
import { Trash, Edit, PhoneCall, Settings, Plus } from 'lucide-react';

const PhonesContent = () => {
  const [phones, setPhones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showBuyNumberPopup, setShowBuyNumberPopup] = useState(false);
  const [showSIPPopup, setShowSIPPopup] = useState(false);
  const [areaCode, setAreaCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [terminationURI, setTerminationURI] = useState('');
  const [sipTrunkUserName, setSipTrunkUserName] = useState('');
  const [sipTrunkPassword, setSipTrunkPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [buyButtonText, setBuyButtonText] = useState('Buy');
  const [connectButtonText, setConnectButtonText] = useState('Connect');

  const fetchPhones = async () => {
    try {
      setLoading(true);
      setError(null);
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch('https://api.aivira.co/phones', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch phones');
      }

      const data = await response.json();
      setPhones(data.phones);
    } catch (error) {
      console.error('Error fetching phones:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhones();
  }, []);

  const handleDeletePhone = async (phoneId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch(`https://api.aivira.co/phones/${phoneId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete phone');
      }

      // Remove the deleted phone from the state
      setPhones((prevPhones) => prevPhones.filter((phone) => phone.id !== phoneId));
      alert('Phone deleted successfully');
    } catch (error) {
      console.error('Error deleting phone:', error);
      setError(error.message);
    }
  };

  const handleBuyNewNumber = () => {
    setShowBuyNumberPopup(true);
    setIsDropdownOpen(false); // Hide the dropdown
  };

  const handleBuyNumberSubmit = async (e) => {
    e.preventDefault();
    setBuyButtonText('Buying...');

    // Check if payment methods exist
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const paymentMethodsResponse = await fetch('https://api.aivira.co/payment_methods', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!paymentMethodsResponse.ok) {
        throw new Error('Failed to fetch payment methods');
      }

      const paymentMethodsData = await paymentMethodsResponse.json();
      if (!paymentMethodsData.payment_methods || paymentMethodsData.payment_methods.length === 0) {
        setError('No payment methods found. Please add a payment method to proceed.');
        setShowBuyNumberPopup(false);
        return;
      }

      // If payment methods exist, proceed with buying the phone number
      const response = await fetch('https://api.aivira.co/add-phone-buy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "area_code": areaCode
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Phone number bought successfully:", data);
        setPhones((prevPhones) => [...prevPhones, { phone_number: data.phone_number, nickname: 'New Number' }]);
        setShowBuyNumberPopup(false);
        alert('Phone number bought successfully!');
      } else {
        throw new Error(data.error || 'Error buying phone number');
      }
    } catch (error) {
      console.error("Failed to buy phone number:", error);
      setError(error.message);
      setShowBuyNumberPopup(false);
    }
  };

  const handleConnectSIP = () => {
    setShowSIPPopup(true);
  };

  const handleSIPSubmit = async (e) => {
    e.preventDefault();
    setConnectButtonText('Connecting...');

    // API call to backend for connecting to a number via SIP trunking
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch('https://api.aivira.co/add-phone-sip', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          phone_number: phoneNumber,
          sip_trunk: {
            termination_uri: terminationURI,
            username: sipTrunkUserName,
            password: sipTrunkPassword,
          },
          agent_id: nickname, // Use nickname or agent_id as needed
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Phone number connected via SIP successfully:", data);
        setPhones((prevPhones) => [...prevPhones, { id: data.import_info.phone_number, number: data.import_info.phone_number, nickname }]);
        setShowSIPPopup(false);
        alert('Phone number connected via SIP successfully!');
      } else {
        throw new Error(data.error || 'Error connecting phone via SIP');
      }
    } catch (error) {
      console.error("Failed to connect phone via SIP:", error);
      setError(error.message);
      setShowSIPPopup(false);
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleAddPhoneClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex-1 p-6">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="flex justify-between items-center mb-6">
        <div className="relative">
          <button
            onClick={handleAddPhoneClick}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 flex items-center"
          >
            <Plus className="mr-2" size={20} />
            Add Phone
          </button>
          {isDropdownOpen && (
            <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button
                  onClick={handleBuyNewNumber}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                  role="menuitem"
                >
                  Buy new number
                </button>
                <button
                  onClick={handleConnectSIP}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                  role="menuitem"
                >
                  Connect to your number via SIP trunking
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showBuyNumberPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="buy-number-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Buy Phone Number</h3>
              <form onSubmit={handleBuyNumberSubmit} className="mt-2 text-left">
                <div className="mb-4">
                  <label htmlFor="areaCode" className="block text-sm font-medium text-gray-700">Area Code (optional)</label>
                  <input type="text" id="areaCode" name="areaCode" value={areaCode} onChange={(e) => setAreaCode(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" placeholder="Enter area code" />
                </div>
                <p className="text-sm text-gray-500 mb-4">This number incurs a monthly fee of $2.00.</p>
                <div className="flex justify-between mt-4">
                  <button type="button" onClick={() => setShowBuyNumberPopup(false)} className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400">Cancel</button>
                  <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">{buyButtonText}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showSIPPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Connect to your number via SIP trunking</h3>
              <form onSubmit={handleSIPSubmit} className="mt-2 text-left">
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number *</label>
                  <input type="tel" id="phoneNumber" name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" placeholder="E.164 format: (+14151113333)" required />
                </div>
                <div className="mb-4">
                  <label htmlFor="terminationURI" className="block text-sm font-medium text-gray-700">Termination URI *</label>
                  <input type="text" id="terminationURI" name="terminationURI" value={terminationURI} onChange={(e) => setTerminationURI(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
                </div>
                <div className="mb-4">
                  <label htmlFor="sipTrunkUserName" className="block text-sm font-medium text-gray-700">SIP Trunk User Name</label>
                  <input type="text" id="sipTrunkUserName" name="sipTrunkUserName" value={sipTrunkUserName} onChange={(e) => setSipTrunkUserName(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div className="mb-4">
                  <label htmlFor="sipTrunkPassword" className="block text-sm font-medium text-gray-700">SIP Trunk Password</label>
                  <input type="password" id="sipTrunkPassword" name="sipTrunkPassword" value={sipTrunkPassword} onChange={(e) => setSipTrunkPassword(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div className="mb-4">
                  <label htmlFor="nickname" className="block text-sm font-medium text-gray-700">Nickname</label>
                  <input type="text" id="nickname" name="nickname" value={nickname} onChange={(e) => setNickname(e.target.value)} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
                </div>
                <div className="flex justify-between mt-4">
                  <button type="button" onClick={() => setShowSIPPopup(false)} className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400">Cancel</button>
                  <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">{connectButtonText}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="text-center">
          <p className="text-gray-600">Loading phones...</p>
        </div>
      ) : error ? (
        <div className="text-center text-red-600">
          <p>Error: {error}</p>
        </div>
      ) : phones.length === 0 ? (
        <div className="text-center text-gray-600">
          <p>No phones found. Add a new phone to get started.</p>
        </div>
      ) : (
        phones.map((phone) => (
          <div key={phone.id} className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{phone.phone_number}</h2>
              <Trash
                className="text-gray-500 cursor-pointer hover:text-red-500"
                onClick={() => handleDeletePhone(phone.phone_number)}
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PhonesContent;
