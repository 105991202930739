import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <div className="space-y-6">
        <section>
          <h2 className="text-2xl font-semibold mb-3">1. Acceptance of Terms</h2>
          <p>By accessing and using Aivira's services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">2. Service Description</h2>
          <p>Aivira provides AI-powered services and tools. We reserve the right to modify, suspend, or discontinue any part of our services at any time without notice.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">3. User Accounts</h2>
          <ul className="list-disc pl-6 mb-2">
            <li>You must provide accurate and complete information when creating an account</li>
            <li>You are responsible for maintaining the security of your account credentials</li>
            <li>You must notify us immediately of any unauthorized use of your account</li>
            <li>We reserve the right to terminate accounts that violate our terms</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">4. User Responsibilities</h2>
          <p>You agree to:</p>
          <ul className="list-disc pl-6 mb-2">
            <li>Use our services in compliance with all applicable laws</li>
            <li>Not engage in any unauthorized or malicious activities</li>
            <li>Not attempt to circumvent any security measures</li>
            <li>Not use our services to harm others or spread harmful content</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">5. Intellectual Property</h2>
          <p>All content and materials available through our services are protected by intellectual property rights. You may not use, modify, or distribute our content without explicit permission.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">6. Payment Terms</h2>
          <ul className="list-disc pl-6 mb-2">
            <li>Subscription fees are billed in advance on a recurring basis</li>
            <li>All payments are non-refundable unless required by law</li>
            <li>We reserve the right to modify pricing with notice</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">7. Limitation of Liability</h2>
          <p>Our services are provided "as is" without any warranties. We shall not be liable for any indirect, incidental, special, or consequential damages.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">8. Changes to Terms</h2>
          <p>We may update these terms at any time. Continued use of our services after changes constitutes acceptance of the new terms.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">9. Contact</h2>
          <p>For any questions regarding these Terms and Conditions, please contact us at support@aivira.co</p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;