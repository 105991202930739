import React from 'react';

const LeftMenuItem = ({ icon, text, active, onClick, rightIcon }) => (
  <div className={`flex items-center justify-between p-4 cursor-pointer ${active ? 'bg-green-100 text-green-800' : 'text-gray-600'}`} onClick={onClick}>
    <div className="flex items-center">
      {icon}
      <span className="ml-2">{text}</span>
    </div>
    {rightIcon && <div className="ml-auto">{rightIcon}</div>}
  </div>
);

export default LeftMenuItem;
