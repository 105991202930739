import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import NewLandingPage from './components/NewLandingPage';
import LandingPage from './components/LandingPage';
import UseCasePage from './components/UseCasePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Dashboard from './components/AiviraUI';
import HealthcarePage from './components/HealthcarePage';
import EducationPage from './components/EducationPage';
import LegalPage from './components/LegalPage';
import HospitalityPage from './components/HospitalityPage';
import CarDealershipPage from './components/AutomotivePage';
import FitnessPage from './components/FitnessPage';
import RetailPage from './components/RetailPage';
import RealEstatePage from './components/RealEstatePage';
import HirePage from './components/HirePage';
import CookiePolicy from './components/CookiePolicy';
import BlogPage from './components/BlogPage';
import PlaygroundPage from './components/PlaygroundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/use-case/:useCase" element={<UseCasePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/healthcare" element={<HealthcarePage />} />
        <Route path="/car-dealership" element={<CarDealershipPage />} />
        <Route path="/fitness" element={<FitnessPage />} />
        <Route path="/retail" element={<RetailPage />} />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/hospitality" element={<HospitalityPage />} />
        <Route path="/real-estate" element={<RealEstatePage />} />
        <Route path="/hire" element={<HirePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/playground" element={<PlaygroundPage />} />
        <Route path="/new" element={<NewLandingPage />} />
        <Route path="/lp" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
