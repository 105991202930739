import React from 'react';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const blogPosts = [
    {
      title: "Aivira: Revolutionizing Appointment Booking with AI - Is Your Business Ready?",
      date: "October 7, 2024",
      excerpt: "Explore how Aivira's AI technology is transforming the way businesses handle appointments and customer interactions, making scheduling more efficient and accessible.",
      link: "https://www.linkedin.com/pulse/aivira-revolutionizing-appointment-booking-ai-your-business-ready-hsvte/",
      image: "https://media.licdn.com/dms/image/v2/D4E12AQF75EHIa97s5Q/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1728325561379?e=1735776000&v=beta&t=1OVsasvtWhKoRq5VJjrs08lsj9CU3iWmilk82LvbGu8"
    },
    {
      title: "Aivira AI: Multilingual Receptionist for Car Dealerships",
      date: "October 14, 2024",
      excerpt: "Discover how Aivira's multilingual AI receptionist is revolutionizing customer service in car dealerships, handling inquiries and appointments in multiple languages.",
      link: "https://www.linkedin.com/pulse/aivira-ai-multilingual-receptionist-car-dealerships-aivira-majie/",
      image: "https://media.licdn.com/dms/image/v2/D4E12AQGr2ZD8ZoJ4wg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1728941124599?e=1735776000&v=beta&t=69EE4sK9EncAwBpu97B6TCO1tonV_OI_7CjjPnyiCuc"
    },
    {
      title: "Growing Need for AI Solutions in the Hospitality Industry",
      date: "October 24, 2024",
      excerpt: "Explore how AI solutions are becoming increasingly essential in the hospitality industry, transforming guest services and operational efficiency.",
      link: "https://www.linkedin.com/pulse/growing-need-ai-solutions-hospitality-industry-aivira-othqe/",
      image: "https://media.licdn.com/dms/image/v2/D4E12AQFzrM7p2C1zOw/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1729890470200?e=1735776000&v=beta&t=watsTKJAqLd8L5mlXg8T1VlHL9a-YQvdbKwew0lIcVU"
    },
    {
      title: "Revolutionizing Real Estate: AI-Powered Receptionists Guide for Professionals",
      date: "October 23, 2024",
      excerpt: "Discover how AI receptionists are transforming the real estate industry by handling inquiries, scheduling viewings, and providing 24/7 customer service.",
      link: "https://www.linkedin.com/pulse/revolutionizing-real-estate-ai-powered-receptionists-guide-professionals-acvke/",
      image: "https://media.licdn.com/dms/image/v2/D4E12AQGPJLOzcSIiMQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1729835702241?e=1735776000&v=beta&t=5PoyLy4HBOkvBur1g-LFmcsLiCQL75S7AoxGxK3IKG4"
    },
    {
      title: "How AI Receptionists Can Boost Mobile Phone Repair Shops",
      date: "October 15, 2024",
      excerpt: "Learn how AI receptionists are helping mobile phone repair shops streamline operations, manage appointments, and improve customer service.",
      link: "https://www.linkedin.com/pulse/how-ai-receptionists-can-boost-mobile-phone-repair-shops-aivira-bzv4f/",
      image: "https://media.licdn.com/dms/image/v2/D4D12AQEG36_k9fZxkQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1729029892089?e=1735776000&v=beta&t=857fwjl-L24GdaM6f1bSTt8WO-MykKnM384zBn7NQtk"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white">
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
          Aivira Blog
        </h1>
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {blogPosts.map((post, index) => (
            <a
              key={index}
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
            >
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
              </div>
              <div className="p-6">
                <div className="text-sm text-gray-500 mb-2">{post.date}</div>
                <h2 className="text-xl font-semibold mb-3 text-gray-800">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <span className="text-purple-600 font-medium inline-flex items-center">
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </a>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link
            to="/"
            className="inline-flex items-center text-purple-600 hover:text-purple-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;