import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Card, CardContent, CardHeader, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Replace 'your_publishable_key' with the actual key when available
const stripePromise = loadStripe('pk_live_51PwsblEj3JIGZB1MSEXmQlpL6k7EOEJx8YM3tGjGSgV2mqAMIlHgynQY1wU2U80RjAFOmYB0r83RtmIF16bYVNsb00yxEBSe3o');

const BillingContent = () => {
  const [activeTab, setActiveTab] = useState('payment-methods');
  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [error, setError] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [methodToDelete, setMethodToDelete] = useState(null);

  const [billingHistory, setBillingHistory] = useState([]);

  const handleDeletePaymentMethod = (method) => {
    setMethodToDelete(method);
    setDeleteConfirmOpen(true);
  };

  const confirmDeletePaymentMethod = async () => {
    if (!methodToDelete) return;

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch(`https://api.aivira.co/payment_methods/${methodToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete payment method');
      }

      // Fetch updated payment methods
      await fetchPaymentMethods();
      setDeleteConfirmOpen(false);
      setMethodToDelete(null);
    } catch (error) {
      console.error('Error deleting payment method:', error);
      setError('Failed to delete payment method. Please try again later.');
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch('https://api.aivira.co/payment_methods', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch payment methods');
      }

      const data = await response.json();
      setPaymentMethods(data.payment_methods || []); // Use the correct property name and provide a fallback
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      setError('Failed to fetch payment methods. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const handleAddPaymentMethod = () => {
    setIsAddCardOpen(true);
  };

  const CardForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitCard = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsLoading(true);

      try {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (error) {
          throw new Error(error.message);
        }

        // Invoke /billing/payment-methods API using fetch
        const response = await fetch('https://api.aivira.co/payment_methods', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id
          })
        });

        if (!response.ok) {
          throw new Error('Failed to add payment method');
        }

        const data = await response.json();
        console.log('Payment method added:', data);
        setIsAddCardOpen(false);
        
        // Fetch updated payment methods
        await fetchPaymentMethods();
        
      } catch (error) {
        setError('Failed to add payment method. Please check your details.');
        console.error('Error adding payment method:', error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Box sx={{ p: 2 }}>
        <form onSubmit={handleSubmitCard}>
          <Box sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 2, backgroundColor: '#f5f5f5' }}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
                hidePostalCode: true,
              }}
            />
          </Box>
          <DialogActions>
            <Button onClick={() => setIsAddCardOpen(false)} color="secondary" disabled={isLoading}>
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={!stripe || isLoading}
            >
              {isLoading ? 'Processing...' : 'Add Card'}
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  };

  return (
    <div className="p-6">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
          <Tab icon={<CreditCardIcon />} iconPosition="start" label="Payment methods" value="payment-methods" />
          <Tab icon={<HistoryIcon />} iconPosition="start" label="Billing history" value="billing-history" />
        </Tabs>
      </Box>
      
      {activeTab === 'billing-history' && (
        <div>
          <table className="w-full border-collapse mt-4">
            <thead>
              <tr>
                <th className="border p-2 text-left">DATE</th>
                <th className="border p-2 text-left">COST</th>
                <th className="border p-2 text-left">MINUTES</th>
                <th className="border p-2 text-left">STATUS</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory.map((item, index) => (
                <tr key={index}>
                  <td className="border p-2">{item.date}</td>
                  <td className="border p-2">{item.cost}*</td>
                  <td className="border p-2">{item.minutes}</td>
                  <td className="border p-2">{item.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="text-sm mt-2">* We charge at the end of each month. The amount shown is an estimate based on usage up to yesterday. For the final and accurate billing amount, please refer to your invoice, which will be provided at the end of the month.</p>
        </div>
      )}

      {activeTab === 'payment-methods' && (
        <div className="mt-4">
          {isLoading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              {paymentMethods.map((method, index) => (
                <Card key={method.id} sx={{ mb: 3 }}>
                  <CardHeader 
                    title={index === 0 ? "Default" : `Card ${index + 1}`} 
                    subheader={`${method.brand.toUpperCase()} **** ${method.last4}`} 
                    action={
                      paymentMethods.length > 1 && (
                        <IconButton 
                          aria-label="delete"
                          onClick={() => handleDeletePaymentMethod(method)}
                          disabled={index === 0}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )
                    }
                  />
                  <CardContent>
                    <div className="mt-2">
                      <span className="text-sm">Expiry date</span>
                      <div>{method.exp_month}/{method.exp_year}</div>
                    </div>
                    <div className="mt-2">
                      <span className="text-sm">Funding</span>
                      <div>{method.funding}</div>
                    </div>
                  </CardContent>
                </Card>
              ))}
              {paymentMethods.length === 0 && (
                <Typography>No payment methods found. Add a new card to get started.</Typography>
              )}
              <Button
                variant="outlined"
                onClick={handleAddPaymentMethod}
                sx={{ mt: 2 }}
              >
                Add payment method
              </Button>
              <Dialog 
                open={isAddCardOpen} 
                onClose={() => setIsAddCardOpen(false)}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>Add Card</DialogTitle>
                <DialogContent>
                  <Elements stripe={stripePromise}>
                    <CardForm />
                  </Elements>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </DialogContent>
              </Dialog>
              <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
              >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <Typography>
                    Are you sure you want to delete this payment method?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={confirmDeletePaymentMethod} color="primary" autoFocus>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BillingContent;
