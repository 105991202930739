import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const NewLandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderRedirecting, setIsHeaderRedirecting] = useState(false);
  const [isHeroRedirecting, setIsHeroRedirecting] = useState(false);
  const [isStarterRedirecting, setIsStarterRedirecting] = useState(false);
  const [isBusinessRedirecting, setIsBusinessRedirecting] = useState(false);
  const [isProRedirecting, setIsProRedirecting] = useState(false);

  const handleStartTrial = (buttonType) => {
    switch(buttonType) {
      case 'header':
        setIsHeaderRedirecting(true);
        break;
      case 'hero':
        setIsHeroRedirecting(true);
        break;
      case 'starter':
        setIsStarterRedirecting(true);
        break;
      case 'business':
        setIsBusinessRedirecting(true);
        break;
      case 'pro':
        setIsProRedirecting(true);
        break;
    }
    if (typeof window !== 'undefined') {
      window.open('https://api.aivira.co/login', '_self');
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-black text-white z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            {/* Logo on the left */}
            <div className="flex items-center">
              <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8" />
              <span className="ml-2 text-xl font-bold">Aivira</span>
            </div>
            {/* Navigation and buttons on the right */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#features" className="text-white hover:text-gray-300 text-sm font-medium">Features</a>
              <a href="#pricing" className="text-white hover:text-gray-300 text-sm font-medium">Pricing</a>
              <a href="https://api.aivira.co/login" className="text-white hover:text-gray-300 text-sm font-medium">Login</a>
              <Button 
                onClick={() => handleStartTrial('header')}
                className="bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-sm text-sm font-medium"
              >
                {isHeaderRedirecting ? 'Redirecting...' : 'Get Started for Free'}
              </Button>
            </div>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4 bg-black text-white">
        <div className="max-w-4xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="text-4xl md:text-5xl font-bold mb-6 leading-tight">
                Transform Your Business Communication with AI
              </h1>
              <p className="text-lg md:text-xl text-gray-300 mb-2">
                First 60 minutes completely free. No credit card required.
              </p>
              <p className="text-lg md:text-xl text-gray-300 mb-8">
                Let our AI handle your business calls 24/7. Automate customer service, book appointments, and never miss an opportunity.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Button 
                  onClick={() => handleStartTrial('hero')}
                  className="bg-white hover:bg-gray-100 text-black px-8 py-3 rounded-sm text-sm font-medium"
                >
                  {isHeroRedirecting ? 'Redirecting...' : 'Start Free Trial'}
                </Button>
                <Button 
                  onClick={() => window.location.href = '#features'}
                  className="border border-white hover:bg-white hover:text-black text-white px-8 py-3 rounded-sm text-sm font-medium transition-colors"
                >
                  Learn More
                </Button>
              </div>
            </div>
            <div className="relative">
              <div className="bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg p-8 relative z-10">
                <img 
                  src="/images/aivira-logo-head.png" 
                  alt="Aivira AI" 
                  className="w-full h-auto rounded-lg shadow-xl"
                />
              </div>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-xl transform translate-x-4 translate-y-4"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section id="features" className="py-20 bg-black text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Powerful Features for Modern Business</h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Everything you need to automate your business communication and provide exceptional customer service.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "24/7 Availability",
                description: "Never miss a call again. Your AI receptionist works around the clock, ensuring every customer gets immediate attention.",
                icon: "🌙"
              },
              {
                title: "Natural Conversations",
                description: "Our advanced AI understands context and nuance, providing human-like interactions that keep your customers engaged.",
                icon: "💬"
              },
              {
                title: "Smart Scheduling",
                description: "Automatically manage appointments, handle cancellations, and send reminders to optimize your business calendar.",
                icon: "📅"
              },
              {
                title: "Intelligent Routing",
                description: "Filter and prioritize calls based on your criteria, ensuring the most important conversations get immediate attention.",
                icon: "🎯"
              },
              {
                title: "Custom AI Training",
                description: "Train your AI to handle specific scenarios and maintain your brand voice across all customer interactions.",
                icon: "🎓"
              },
              {
                title: "Seamless Integration",
                description: "Connect with your existing tools and software for a unified business communication system.",
                icon: "🔄"
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="bg-gray-900 p-8 rounded-sm border border-gray-800 hover:border-gray-700 transition-colors"
              >
                <div className="text-4xl mb-6">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-400 leading-relaxed">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>


      {/* Pricing Section */}
      <section id="pricing" className="py-20 bg-black text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Choose Your Plan</h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Start with our free trial and upgrade anytime to match your business needs.
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Starter",
                price: "$99",
                description: "Perfect for small businesses getting started with AI communication",
                features: [
                  "Up to 500 calls/month",
                  "24/7 Call Handling",
                  "Basic Call Analytics",
                  "Email Notifications",
                  "Standard Support"
                ]
              },
              {
                name: "Business",
                price: "$199",
                description: "Ideal for growing businesses needing advanced features",
                features: [
                  "Up to 1500 calls/month",
                  "Advanced Analytics Dashboard",
                  "Priority Call Routing",
                  "SMS & Email Notifications",
                  "CRM Integration",
                  "Priority Support"
                ],
                popular: true
              },
              {
                name: "Enterprise",
                price: "$399",
                description: "For businesses requiring maximum flexibility and support",
                features: [
                  "Unlimited calls/month",
                  "Custom AI Training",
                  "Advanced Integrations",
                  "Dedicated Account Manager",
                  "24/7 Premium Support",
                  "Custom Reporting"
                ]
              }
            ].map((plan, index) => (
              <div 
                key={index} 
                className={`bg-gray-900 p-8 rounded-sm border ${
                  plan.popular ? 'border-white' : 'border-gray-800'
                } hover:border-gray-700 transition-colors relative`}
              >
                {plan.popular && (
                  <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span className="bg-white text-black px-4 py-1 text-sm font-medium">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className="mb-8">
                  <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                  <div className="text-3xl font-bold mb-2">
                    {plan.price}<span className="text-xl font-normal text-gray-400">/month</span>
                  </div>
                  <p className="text-gray-400">{plan.description}</p>
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center text-gray-300">
                      <svg className="w-5 h-5 text-white mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <Button
                  onClick={() => handleStartTrial(plan.name.toLowerCase())}
                  className={`w-full py-3 rounded-sm ${
                    plan.popular
                      ? 'bg-white hover:bg-gray-100 text-black'
                      : 'border border-white hover:bg-white hover:text-black text-white'
                  } transition-colors font-medium`}
                >
                  {plan.name === 'Starter' && isStarterRedirecting ? 'Redirecting...' :
                   plan.name === 'Business' && isBusinessRedirecting ? 'Redirecting...' :
                   plan.name === 'Pro' && isProRedirecting ? 'Redirecting...' :
                   'Start Free Trial'}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black text-white py-16 border-t border-gray-800">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-12">
            <div>
              <div className="flex items-center mb-6">
                <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8" />
                <span className="ml-2 text-xl font-bold">Aivira</span>
              </div>
              <p className="text-gray-400 text-sm">
                Transforming business communication with AI-powered solutions that work 24/7.
              </p>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Product</h3>
              <ul className="space-y-3">
                <li><a href="#features" className="text-gray-400 hover:text-white text-sm">Features</a></li>
                <li><a href="#pricing" className="text-gray-400 hover:text-white text-sm">Pricing</a></li>
                <li><a href="#demo" className="text-gray-400 hover:text-white text-sm">Request Demo</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Company</h3>
              <ul className="space-y-3">
                <li><Link to="/about" className="text-gray-400 hover:text-white text-sm">About</Link></li>
                <li><Link to="/blog" className="text-gray-400 hover:text-white text-sm">Blog</Link></li>
                <li><a href="https://discord.gg/3PQMZs8Rvz" className="text-gray-400 hover:text-white text-sm">Community</a></li>
                <li><Link to="/careers" className="text-gray-400 hover:text-white text-sm">Careers</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-4 text-gray-300">Legal</h3>
              <ul className="space-y-3">
                <li><Link to="/privacy" className="text-gray-400 hover:text-white text-sm">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-gray-400 hover:text-white text-sm">Terms of Service</Link></li>
                <li><Link to="/cookie-policy" className="text-gray-400 hover:text-white text-sm">Cookie Policy</Link></li>
                <li><Link to="/gdpr" className="text-gray-400 hover:text-white text-sm">GDPR Compliance</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-16 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="https://twitter.com/aivira" className="text-gray-400 hover:text-white">
                Twitter
              </a>
              <a href="https://linkedin.com/company/aivira" className="text-gray-400 hover:text-white">
                LinkedIn
              </a>
              <a href="https://github.com/aivira" className="text-gray-400 hover:text-white">
                GitHub
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default NewLandingPage;
