import React, { useState } from 'react';
import { ChevronRight, Phone, Building2, Users, Sparkles, Check } from 'lucide-react';

const LandingPage = () => {
  const [redirectingStates, setRedirectingStates] = useState({
    login: false,
    trial1: false,
    trial2: false,
    trial3: false,
    trial4: false,
    trial5: false
  });

  const industries = [
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Healthcare",
      description: "Streamline patient care and appointments",
      url: "https://try.aivira.co/healthcare"
    },
    {
      icon: <Users size={28} className="text-purple-600" />,
      title: "Education",
      description: "Enhance student and parent communication",
      url: "https://try.aivira.co/education"
    },
    {
      icon: <Sparkles size={28} className="text-purple-600" />,
      title: "Legal",
      description: "Manage client inquiries and consultations",
      url: "https://try.aivira.co/legal"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Real Estate",
      description: "Handle property inquiries and showings",
      url: "https://try.aivira.co/real-estate"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Car Dealers",
      description: "Manage service appointments and sales inquiries",
      url: "https://try.aivira.co/car-dealership"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Hospitality",
      description: "Streamline reservations and guest services",
      url: "https://try.aivira.co/hospitality"
    },
    {
      icon: <Users size={28} className="text-purple-600" />,
      title: "Fitness",
      description: "Manage class bookings and member inquiries",
      url: "https://try.aivira.co/fitness"
    },
    {
      icon: <Building2 size={28} className="text-purple-600" />,
      title: "Retail",
      description: "Handle customer service and order inquiries",
      url: "https://try.aivira.co/retail"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/95 backdrop-blur-sm z-50 border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-12">
              <div className="flex items-center space-x-2">
                <Phone className="w-7 h-7 text-purple-600" />
                <span className="text-xl font-semibold text-gray-900">Aivira</span>
              </div>
              <div className="hidden md:flex space-x-8">
                <a href="#how-it-works" className="text-gray-600 hover:text-purple-600 text-sm font-medium">How it works</a>
                <a href="#features" className="text-gray-600 hover:text-purple-600 text-sm font-medium">Features</a>
                <a href="#pricing" className="text-gray-600 hover:text-purple-600 text-sm font-medium">Pricing</a>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <button 
                className="text-sm font-medium text-gray-600 hover:text-purple-600"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, login: true }));
                  if (typeof window !== 'undefined') {
                    const accessToken = localStorage.getItem('accessToken');
                    if (accessToken) {
                      window.location.href = '/dashboard';
                    } else {
                      window.open('https://api.aivira.co/login', '_self');
                    }
                  }
                }}
              >
                {redirectingStates.login ? 'Redirecting...' : 'Login'}
              </button>
              <button 
                className="px-5 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-colors"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial1: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
              >
                {redirectingStates.trial1 ? 'Redirecting...' : 'Get Started Free'}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="pt-32 pb-24 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 tracking-tight mb-6">
            Your AI Phone <span className="text-purple-600">Receptionist</span>
          </h1>
          <p className="text-lg md:text-xl text-gray-600 mb-10 max-w-2xl mx-auto">
            Revolutionize your customer service. Boost efficiency. Drive growth.
          </p>
          <button 
            className="px-8 py-3 text-base font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 transition-all shadow-sm hover:shadow"
            onClick={() => {
              setRedirectingStates(prev => ({ ...prev, trial2: true }));
              if (typeof window !== 'undefined') {
                window.open('https://api.aivira.co/login', '_self');
              }
            }}
          >
            {redirectingStates.trial2 ? 'Redirecting...' : 'Get Started for Free'}
          </button>
          <br/><br/>
          <p className="text-lg text-gray-600 mb-10">
            First 60 minutes completely free. No credit card required.
          </p>

        </div>
      </div>

      {/* Industry Solutions */}
      <div id="features" className="bg-gray-50 py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Tailored AI Solutions for Every Industry
            </h2>
            <p className="text-lg text-gray-600">
              Discover how our AI receptionist can transform your business
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {industries.map((industry, index) => (
              <div key={index} className="bg-white p-6 rounded-xl border border-gray-100 hover:shadow-md transition-shadow">
                <div className="mb-4">
                  {industry.icon}
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{industry.title}</h3>
                <p className="text-gray-600 text-sm mb-4">{industry.description}</p>
                <button 
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open(industry.url, '_self');
                    }
                  }}
                  className="text-sm font-medium text-purple-600 flex items-center hover:text-purple-700"
                >
                  Learn more <ChevronRight className="ml-1 w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div id="how-it-works" className="py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              How Aivira Works
            </h2>
            <p className="text-lg text-gray-600">
              Increase your efficiency and reduce your costs in 3 simple steps
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-12">
            {[
              { title: "Integrate with your software", description: "1-click integration to your business management software", icon: "🔗" },
              { title: "Forward calls to Aivira", description: "Forward calls from any phone system to your Aivira number", icon: "📞" },
              { title: "Customize your calls", description: "Easily teach your AI to handle calls just the way you like it", icon: "🎛️" }
            ].map((step, index) => (
              <div key={index} className="bg-white p-8 rounded-2xl shadow-lg text-center">
                <div className="text-5xl mb-6">{step.icon}</div>
                <h3 className="text-2xl font-semibold mb-4">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>

          <div className="mt-20">
            <h2 className="text-3xl font-bold mb-10 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
              Aivira: The Future of Business Communication
            </h2>
            <div className="flex flex-col items-center space-y-6 mt-8">
              {[
                "Available 24/7",
                "5x cheaper than a traditional call-center",
                "Handles all inquiries, bookings, and customer service"
              ].map((benefit, index) => (
                <div key={index} className="bg-white rounded-full px-8 py-4 shadow-lg flex items-center">
                  <Check className="text-green-500 mr-4" size={24} />
                  <span className="text-lg font-medium">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="bg-gray-50 py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
            Simple, Transparent Pricing
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Professional Plan */}
            <div className="bg-white p-8 rounded-2xl shadow-xl relative">
              <div className="text-center mb-6">
                <h3 className="text-2xl font-bold mb-2">Professional</h3>
                <div className="text-4xl font-bold text-purple-600 mb-2">$49<span className="text-xl font-normal text-gray-600">/month</span></div>
                <p className="text-gray-600">Perfect for small businesses</p>
              </div>
              <div className="space-y-4 mb-8">
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Up to 250 minutes</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Then $0.25 per minute</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>CRM Integration</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>SMS Notifications</span>
                </div>
              </div>
              <button 
                className="w-full bg-purple-600/90 hover:bg-purple-700 text-white py-2.5 text-sm rounded-full transition-colors"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial3: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
              >
                {redirectingStates.trial3 ? 'Redirecting...' : 'Get Started for Free'}
              </button>
            </div>

            {/* Scale Plan */}
            <div className="bg-white p-8 rounded-2xl shadow-xl relative transform scale-105 border-2 border-purple-500">
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span className="bg-purple-500 text-white px-4 py-1 rounded-full text-sm">Most Popular</span>
              </div>
              <div className="text-center mb-6">
                <h3 className="text-2xl font-bold mb-2">Scale</h3>
                <div className="text-4xl font-bold text-purple-600 mb-2">$99<span className="text-xl font-normal text-gray-600">/month</span></div>
                <p className="text-gray-600">For growing businesses</p>
              </div>
              <div className="space-y-4 mb-8">
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Up to 500 minutes</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Then $0.25 per minute</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Custom Integrations</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Advanced Analytics</span>
                </div>
              </div>
              <button 
                className="w-full bg-purple-600/90 hover:bg-purple-700 text-white py-2.5 text-sm rounded-full transition-colors"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial4: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
              >
                {redirectingStates.trial4 ? 'Redirecting...' : 'Get Started for Free'}
              </button>
            </div>

            {/* Growth Plan */}
            <div className="bg-white p-8 rounded-2xl shadow-xl relative">
              <div className="text-center mb-6">
                <h3 className="text-2xl font-bold mb-2">Growth</h3>
                <div className="text-4xl font-bold text-purple-600 mb-2">$199<span className="text-xl font-normal text-gray-600">/month</span></div>
                <p className="text-gray-600">For power users</p>
              </div>
              <div className="space-y-4 mb-8">
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Up to 1000 minutes</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Then $0.25 per minute</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Custom Integrations</span>
                </div>
                <div className="flex items-center space-x-3">
                  <Check className="text-green-500 flex-shrink-0" size={20} />
                  <span>Priority Support</span>
                </div>
              </div>
              <button 
                className="w-full bg-purple-600/90 hover:bg-purple-700 text-white py-2.5 text-sm rounded-full transition-colors"
                onClick={() => {
                  setRedirectingStates(prev => ({ ...prev, trial5: true }));
                  if (typeof window !== 'undefined') {
                    window.open('https://api.aivira.co/login', '_self');
                  }
                }}
              >
                {redirectingStates.trial5 ? 'Redirecting...' : 'Get Started for Free'}
              </button>
            </div>
          </div>

          <div className="mt-12 text-center">
            <p className="text-lg mb-4">All plans include:</p>
            <div className="grid md:grid-cols-3 gap-6 max-w-4xl mx-auto">
              <div className="text-center">
                <div className="text-4xl mb-4">🎯</div>
                <h4 className="text-xl font-semibold mb-2">No Setup Fee</h4>
                <p className="text-gray-600">Get started immediately</p>
              </div>
              <div className="text-center">
                <div className="text-4xl mb-4">📞</div>
                <h4 className="text-xl font-semibold mb-2">14-Day Free Trial</h4>
                <p className="text-gray-600">Try before you commit</p>
              </div>
              <div className="text-center">
                <div className="text-4xl mb-4">🚀</div>
                <h4 className="text-xl font-semibold mb-2">Cancel Anytime</h4>
                <p className="text-gray-600">No long-term contracts</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
