import React, { useState } from 'react';
import './HirePage.css';

const HirePage = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [selectedRole, setSelectedRole] = useState('Clerk');
  const [showDemo, setShowDemo] = useState(false);
  const [callStatus, setCallStatus] = useState('');

  const handleJobTitleChange = (e) => setJobTitle(e.target.value);
  const handleJobDescriptionChange = (e) => setJobDescription(e.target.value);
  
  const isValidUSPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phoneNumber.trim());
  };

  const handlePhoneNumbersChange = (e) => {
    const input = e.target.value;
    setPhoneNumbers(input);
    
    const numbers = input.split(',').map(num => num.trim());
    const invalidNumbers = numbers.filter(num => num && !isValidUSPhoneNumber(num));
    
    if (invalidNumbers.length > 0) {
      setPhoneNumberError('Please enter valid US phone numbers.');
    } else {
      setPhoneNumberError('');
    }
  };

  const handleRoleSelect = (role) => setSelectedRole(role);

  const handleCallNumbers = async () => {
    if (jobTitle && jobDescription && phoneNumbers && !phoneNumberError) {
      const numberList = phoneNumbers.split(',').map(num => num.trim());
      const validNumbers = numberList.filter(isValidUSPhoneNumber);
      
      if (validNumbers.length === 0) {
        setCallStatus('Please enter at least one valid US phone number.');
        return;
      }

      setCallStatus('Initiating calls...');
      try {
        const response = await fetch('https://api.aivira.co/create-job-call', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            job_title: jobTitle,
            job_description: jobDescription,
            phone_number: validNumbers[0], // Using the first valid phone number
          }),
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const result = await response.json();
        setCallStatus(`Call initiated successfully for ${validNumbers[0]}`);
      } catch (error) {
        setCallStatus('Error initiating call. Please try again.');
        console.error('Error:', error);
      }
    } else {
      setCallStatus('Please enter a valid job title, job description, and at least one valid US phone number.');
    }
  };

  const handleTryAivira = () => {
    alert('Redirecting to Aivira Hire setup...');
    // Here you would implement the redirection to the Aivira Hire setup page
  };

  const handleSeeDemo = () => setShowDemo(true);
  const handleSignIn = () => {
    alert('Redirecting to sign in page...');
    // Here you would implement the redirection to the sign in page
  };

  return (
    <div className="container">
      <header>
        <div className="logo">Aivira <span>Hire</span></div>
        <nav>
          <a href="#features">Features</a>
          <a href="#how-it-works">How it works</a>
          <a href="#use-cases">Use cases</a>
          <button className="sign-in-btn" onClick={handleSignIn}>Sign in</button>
        </nav>
        <button className="demo-btn" onClick={handleSeeDemo}>See a demo</button>
      </header>

      <main>
        <section className="hero">
          <h1>Make the call. <span className="highlight">Hire faster.</span></h1>
          <p className="subheading">Accelerate hiring with voice-powered AI screening that adapts to every role and organization's needs</p>
          
          <div className="demo-area">
            <div className="input-group">
              <label htmlFor="phoneNumbers">Candidate Phone Numbers (US only)</label>
              <input 
                id="phoneNumbers"
                type="text" 
                placeholder="Enter US phone numbers, separated by commas" 
                value={phoneNumbers}
                onChange={handlePhoneNumbersChange}
              />
              {phoneNumberError && <p className="error-message">{phoneNumberError}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="jobTitle">Job Title</label>
              <input 
                id="jobTitle"
                type="text" 
                placeholder="Enter job title" 
                value={jobTitle}
                onChange={handleJobTitleChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="jobDescription">Job Description</label>
              <textarea 
                id="jobDescription"
                placeholder="Enter job description" 
                value={jobDescription}
                onChange={handleJobDescriptionChange}
              />
            </div>
            <button className="call-btn" onClick={handleCallNumbers} disabled={!!phoneNumberError}>
              Initiate Calls
            </button>
            {callStatus && <p className="call-status">{callStatus}</p>}
            <p className="test-drive-text">Or try Aivira Hire with our sample roles:</p>
            <div className="role-buttons">
              {['Clerk', 'Engineer', 'Designer', 'Manager'].map(role => (
                <button 
                  key={role}
                  className={`role-btn ${selectedRole === role ? 'active' : ''}`}
                  onClick={() => handleRoleSelect(role)}
                >
                  {role}
                </button>
              ))}
            </div>
            <div className="job-card">
              <h3>{selectedRole === 'Clerk' ? 'Seasonal Store Clerk (Dec - Feb)' : 
                   selectedRole === 'Engineer' ? 'Software Engineer' :
                   selectedRole === 'Designer' ? 'UX/UI Designer' : 'Project Manager'}</h3>
              <p>{selectedRole === 'Clerk' ? 'Bullseye • Part-Time • San Jose, CA' : 
                  selectedRole === 'Engineer' ? 'TechCorp • Full-Time • Remote' :
                  selectedRole === 'Designer' ? 'CreativeCo • Full-Time • New York, NY' : 'ManagePro • Full-Time • Chicago, IL'}</p>
            </div>
            <button className="try-aivira-btn" onClick={handleTryAivira}>
              Try Aivira Hire
            </button>
          </div>
        </section>
      </main>

      <section id="features" className="features">
        <h1 className="section-title">How Aivira Hire Works</h1>
        <div className="feature-grid">
          {[
            {title: "Automated Interviews", description: "AI-powered voice agents conduct interviews and gather key insights automatically."},
            {title: "Tailored Questions", description: "Instantly create screening guidelines based on the role you're looking to fill."},
            {title: "Flexible Connectivity", description: "Whether candidates call you or you call them, we handle it seamlessly."},
            {title: "Instant Insights", description: "Receive immediate summaries and qualifications for each candidate, ready for your decision."}
          ].map((feature, index) => (
            <div key={index} className="feature">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div id="how-it-works" className="call-options">
          <h3>Two Ways to Use Aivira Hire</h3>
          <p>Aivira Hire enables on-demand candidate interviews and allows companies to initiate interviews anytime</p>
          <div className="call-types">
            {[
              {title: "Outbound Calls", description: "Initiate bulk calls to candidates and review results in real-time."},
              {title: "Inbound Calls", description: "Candidates can call in at their convenience for an automated interview experience."}
            ].map((type, index) => (
              <div key={index} className="call-type">
                <h4>{type.title}</h4>
                <p>{type.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {showDemo && (
        <div className="demo-modal">
          <h2>Aivira Hire Demo</h2>
          <p>Experience Aivira Hire in action.</p>
          <button onClick={() => setShowDemo(false)}>Close Demo</button>
        </div>
      )}
    </div>
  );
};

export default HirePage;