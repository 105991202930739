import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const PlaygroundPage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io("https://aivira-voice-ai.vercel.app");

    // Handle incoming audio responses
    socketRef.current.on('response_audio', (data) => {
      const audioBlob = new Blob([data.audio], { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      if (audioRef.current) {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
      }
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (socketRef.current) {
          socketRef.current.emit('audio_chunk', { audio: event.data });
        }
      };

      mediaRecorderRef.current.start(100); // Collect audio every 100ms
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0e2a47] to-[#1a4980] p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-white mb-8">Aivira Voice AI Playground</h1>
        
        <div className="bg-white/10 backdrop-blur-lg rounded-lg p-8 shadow-xl">
          <div className="flex flex-col items-center gap-6">
            <div className="flex gap-4">
              <button
                onClick={startRecording}
                disabled={isRecording}
                className={`px-6 py-3 rounded-full font-semibold transition-all
                  ${isRecording 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-[#1abc9c] hover:bg-[#16a085] text-white'
                  }`}
              >
                Start Talking
              </button>
              
              <button
                onClick={stopRecording}
                disabled={!isRecording}
                className={`px-6 py-3 rounded-full font-semibold transition-all
                  ${!isRecording 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-red-500 hover:bg-red-600 text-white'
                  }`}
              >
                Stop
              </button>
            </div>

            <div className="w-full max-w-md">
              <audio ref={audioRef} controls className="w-full" />
            </div>

            <div className="text-white text-center">
              <p className="text-lg mb-2">Status: {isRecording ? 'Recording...' : 'Ready'}</p>
              <p className="text-sm opacity-75">
                Click "Start Talking" to begin a conversation with Aivira Voice AI
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaygroundPage;