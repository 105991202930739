import React from 'react';
import { Link } from 'react-router-dom';
import { Check } from 'lucide-react';

const RetailPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white">
      <header className="flex justify-between items-center p-4 max-w-7xl mx-auto">
        <div className="text-3xl font-bold text-gray-800 flex items-center">
          <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-8 h-8 mr-2" />
          Aivira
        </div>
        <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md">Book a Demo</a>
      </header>
      
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-20">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            AI Receptionist for Retail
          </h1>
          <p className="text-xl mb-8">Enhance customer service, manage inquiries, and provide 24/7 support for your retail business.</p>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-12">
            <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-md w-full md:w-auto">Book a Demo</a>
            <Link to="/" className="inline-block px-6 py-3 bg-gray-200 hover:bg-gray-300 text-gray-800 rounded-md w-full md:w-auto">Learn More</Link>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Key Features for Retail</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">24/7 Customer Support</h3>
                <p>Provide round-the-clock assistance for product inquiries, order status, and general information.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Multilingual Capability</h3>
                <p>Communicate with customers in their preferred language, improving accessibility and satisfaction.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">CRM Integration</h3>
                <p>Seamlessly integrate with your existing retail management systems for efficient customer data handling.</p>
              </div>
            </div>
            <div className="flex items-start">
              <Check className="mr-2 text-green-500 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Cost Reduction</h3>
                <p>Significantly reduce operational costs while improving customer services and staff efficiency.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Benefits for Retail Businesses</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Improved Customer Service</h3>
              <p>Provide instant responses to customer inquiries, enhancing satisfaction and loyalty.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Efficient Order Management</h3>
              <p>Streamline order tracking, returns, and exchanges, optimizing your retail operations.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Increased Sales Opportunities</h3>
              <p>Capture and nurture leads, provide product recommendations, and boost conversion rates.</p>
            </div>
          </div>
        </section>

        <section className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-8">Ready to transform your retail business?</h2>
          <a href="https://calendly.com/aivira/aivira-ai-receptionist-demo" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-md">Get Started Now</a>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
          <div className="mt-4">
            <Link to="/privacy" className="text-gray-300 hover:text-white mr-4">Privacy Policy</Link>
            <Link to="/terms" className="text-gray-300 hover:text-white">Terms of Service</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default RetailPage;